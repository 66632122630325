<template>
  <div v-frag>
    <hero-with-image :title="pageTitle"
                     :subtitle="mobilProviderId === 3 ? 'vodafone.homePageSubtitle' : 'services.simpleRepair'"
                     :image="imagePath"
                     v-if="brand && !isLoading"></hero-with-image>
    <v-container class="my-10" v-if="brand && !isLoading">
      <v-row>
        <v-col cols="12">
          <div v-html="brand.generalInfo"></div>
        </v-col>
        <v-col cols="12">
          <list-services-no-roles :list-this-items="filteredAvsList"></list-services-no-roles>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="my-10" v-if="!brand && !isLoading">
      <v-row>
        <v-col cols="12" class="text-center">
          <div class="display-1">
            A kiválaszott {{ mobilProviderName.charAt(0).toUpperCase() + mobilProviderName.slice(1) }} szolgáltató adatlapja nem található.
          </div>
        </v-col>
        <v-col cols="12" class="text-center mb-8">
          <div>
            Kérjük látogasson vissza egy későbbi időpontban.
          </div>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn class="primary" @click="$router.push({name: 'home'})">
            Vissza a főoldalra
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="my-10" v-if="isLoading">
      <v-row>
        <v-col cols="12" class="text-center mb-8">
          <v-progress-circular color="primary" size="48" indeterminate></v-progress-circular>
        </v-col>
        <v-col cols="12" class="text-center">
          <div class="display-1">
            Az adatlap betöltése folyamatban...
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import HeroWithImage from "../../../components/titles/HeroWithImage.vue";
  import ListServicesNoRoles from 'src/components/services/ListServicesNoRoles';

  export default {
    name: 'MobilProvidersHomePage',
    components: {
      HeroWithImage, ListServicesNoRoles
    },
    async beforeMount() {
      this.isLoading = true;
      this.brand = await this.getMobilProvider();
      // telekomnál és fliplnél szét kell szetni az avseket
      if (this.$route.name === 'telekomHome') {
        this.pageTitle = this.brand.mobilProviderName;
        this.imagePath = this.brand.imagePath;
        this.brand.availableService.forEach((item) => {
          if ([1,2].includes(item.id)) {
            this.filteredAvsList.push(item);
          }
        });
      } else if (this.$route.name === 'flipHome') {
        this.pageTitle = 'Flip';
        // masterban ezt kell használni
        // this.imagePath = 'data/crm/mobil_provider/flip640x640.png';
        this.imagePath = 'data/mobil_provider/flip640x640_3wshwz84.png';
        this.brand.availableService.forEach((item) => {
          if ([111,112].includes(item.id)) {
            this.filteredAvsList.push(item);
          }
        })
      } else {
        this.pageTitle = this.brand.mobilProviderName;
        this.imagePath = this.brand.imagePath;
        this.filteredAvsList = this.brand.availableService;
      }
      // console.log('MobilProvidersHomePage - this.brand: ', this.brand);
      this.isLoading = false;
    },
    props: {
      mobilProviderId: {
        type: Number,
        default: 999,
      },
      mobilProviderName: {
        type: String,
        default: 'EMPTY',
      },
    },
    data() {
      return {
        brand: {},
        isLoading: true,
        filteredAvsList: [],
        pageTitle: '',
        imagePath: '',
      };
    },
    methods: {
      async getMobilProvider() {
        return await this.$store.dispatch('getMobilProviderById', this.mobilProviderId);
      },
    },
  };
</script>

<style scoped>

</style>

<template>
  <v-container>
    <v-row justify="center">
      <template v-for="(item, index) in listThisItems">
        <v-col cols="12" sm="auto" :key="index">
          <service-card :image="item.image"
                        :title="item.title ? item.title : item.warrantyTypeName"
                        :path="useAltPath ? item.altPath : item.path"
                        :available-service-id="item.id"
                        :is-external-link="item.isExternal"></service-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import ServiceCard from "./ServiceCard.vue";

export default {
  name: 'ListServicesNoRoles',
  props: {
    listThisItems: Array,
    useAltPath: Boolean,
  },
  components: {
    ServiceCard,
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
